import Image from 'next/image'
import * as React from 'react'

import { Text } from '@wartek-id/text'

const PageNotFound = () => {
  return (
    <div className="pt-20">
      <div className="max-w-[600px]">
        <Image
          src="/images/img-fence.png"
          width={110}
          height={110}
          alt="Page not found"
        />
        <Text variant="display-sm" className="mt-9">
          Halaman tidak ditemukan
        </Text>
        <Text variant="body-lg" color="subdued" className="mt-3">
          Pastikan alamat yang Anda masukkan sudah benar lalu coba lagi.
        </Text>
      </div>
    </div>
  )
}

PageNotFound.layoutOpts = {
  hasHeader: true,
  classNameProp: '!bg-white flex justify-center',
  classNameLayout: '!bg-white',
}

export default PageNotFound
